import React from "react";
import Image from "next/image";
import logo from "../../../images/logo.svg";
import { Box, BoxProps, Text } from "@chakra-ui/react";
import Link from "next/link";

export default function Logo(props: {} & BoxProps) {
  return (
    <Box {...props}>
      <Link href="/">
        <Image src={logo} alt="I_Ready - Interview questions" />
      </Link>
    </Box>
  );
}
