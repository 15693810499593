import slugify from "slugify";

export function createSlug(title: string, id: string) {
  const formattedTitle = slugify(title, {
    lower: true,
    remove: /[*+~.()'"!?:@]/g,
  });

  return `${formattedTitle}__${id}`;
}

export function createCategorySlug(name: string) {
  return name.toLowerCase().split(" ").join("-");
}
