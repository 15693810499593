import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Category, Complexity, Tag } from "../../../../types";

type FilterType = "category" | "complexity" | "search" | "tags" | "sort";

export interface Filter {
  type: FilterType;
  value: Category | Complexity | string;
  icon?: string;
}

export interface Filters {
  categories?: Category[];
  complexity?: Complexity[];
  search?: string;
  tags?: Tag[];
  sort?: string;
}

export interface FiltersContextVariables {
  filters: Filters;
  initialFilters: Filters;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      categories?: Category[];
      complexity?: Complexity[];
      tags?: Tag[];
      search?: string;
      sort?: string;
    }>
  >;
}

export const FilterContext = createContext<FiltersContextVariables>({} as any);

export default function useFilters() {
  const { filters, setFilters, initialFilters } = useContext<FiltersContextVariables>(FilterContext);

  const addFilter = useCallback(
    (filter: Filter) => {
      if (filter.type === "category") {
        setFilters(prev => ({
          ...prev,
          categories: [...(prev.categories || []), filter.value as Category],
        }));
      }
      if (filter.type === "search") {
        setFilters(prev => ({
          ...prev,
          search: filter.value as string,
        }));
      }
      if (filter.type === "complexity") {
        setFilters(prev => ({
          ...prev,
          complexity: !filters.complexity?.some(value => value === filter.value) ? [...(prev.complexity || []), filter.value as Complexity] : prev.complexity,
        }));
      }
      if (filter.type === "tags") {
        setFilters(prev => ({
          ...prev,
          tags: [...(prev.tags || []), filter.value as Tag],
        }));
      }
      if (filter.type === "sort") {
        setFilters(prev => ({
          ...prev,
          sort: filter.value as string,
        }));
      }
    },
    [filters.complexity, setFilters],
  );

  const removeFilter = useCallback(
    (filter: Filter) => {
      if (filter.type === "category") {
        setFilters(prev => ({
          ...prev,
          categories: prev.categories?.filter(item => item.id !== (filter.value as Category).id),
        }));
      }
      if (filter.type === "complexity") {
        setFilters(prev => ({
          ...prev,
          complexity: prev.complexity?.filter(item => item !== filter.value),
        }));
      }
      if (filter.type === "search") {
        setFilters(prev => ({
          ...prev,
          search: "",
        }));
      }
      if (filter.type === "tags") {
        setFilters(prev => ({
          ...prev,
          tags: prev.tags?.filter(item => item.id !== (filter.value as Tag).id),
        }));
      }
    },
    [setFilters],
  );

  const categoriesFilter = useMemo<string[]>(() => filters.categories?.map(item => item.id) || [], [filters.categories]);
  const complexityFilter = useMemo<Complexity[]>(() => filters.complexity || [], [filters.complexity]);
  const tagsFilter = useMemo<string[]>(() => filters.tags?.map(item => item?.id as string) || [], [filters.tags]);
  const searchFilter = useMemo<string | undefined>(() => filters.search, [filters.search]);
  const sortFilter = useMemo<string | undefined>(() => filters.sort, [filters.sort]);

  const filtersList = useMemo<Filter[]>(
    () => [
      ...(filters.categories?.map(item => ({ type: "category" as FilterType, value: item, icon: item.icon || "" })) || []),
      ...(filters.tags?.map(item => ({ type: "tags" as FilterType, value: item })) || []),
      ...(filters.complexity?.map(item => ({ type: "complexity" as FilterType, value: item })) || []),
    ],
    [filters.categories, filters.complexity, filters.tags],
  );

  return {
    filters,
    setFilters,
    initialFilters,
    filtersList,
    categoriesFilter,
    complexityFilter,
    searchFilter,
    sortFilter,
    addFilter,
    removeFilter,
    tagsFilter,
  };
}
