import { Box, Flex } from "@chakra-ui/react";
import Header from "../Header";
import Footer from "../Footer";

interface Props {
  children: React.ReactNode;
  withFooter?: boolean;
}

const Layout = ({ children, withFooter, ...props }: Props) => {
  return (
    <>
      <Header />
      {/*<UnderHeader>Breadcrumbs will be here soon</UnderHeader>*/}
      <Flex
        maxW="1240px"
        flexDirection="column"
        paddingLeft="15px"
        paddingRight="15px"
        justifyContent="space-between"
        alignItems="flex-start"
        margin="0 auto"
        minHeight="calc(100vh - 130px)"
        pt={8}
        {...props}
      >
        {children}
      </Flex>
      {withFooter && <Footer />}
    </>
  );
};

export default Layout;
