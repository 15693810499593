import { DiNodejs } from "react-icons/di";
import { SiApollographql, SiGraphql } from "react-icons/si";
import { IconBaseProps } from "react-icons";
import { AngularIcon, CssIcon, HtmlIcon, JavascriptIcon, ReactIcon, TypeScriptIcon, WebpackIcon } from "./icons";

const CATEGORIES_ICONS_MAP: Record<string, any> = {
  angular: AngularIcon,
  apollo: SiApollographql,
  css: CssIcon,
  html: HtmlIcon,
  react: ReactIcon,
  typescript: TypeScriptIcon,
  javascript: JavascriptIcon,
  nodeJs: DiNodejs,
  graphql: SiGraphql,
  webpack: WebpackIcon,
};

interface Props extends IconBaseProps {
  name: string;
}

export default function DevIcon({ name, ...props }: Props) {
  const Icon = CATEGORIES_ICONS_MAP[name];

  return <Icon {...props} />;
}
