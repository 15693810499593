import HeaderContainer from "./HeaderContainer";
import { useCallback, useState } from "react";
import Logo from "./Logo";
import MenuToggle from "./MenuToggle";
import MenuLinks from "./MenuLinks";
import { Box } from "@chakra-ui/react";
import Drawer from "../Drawer";

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleToggle = useCallback(() => setIsOpen(open => !open), []);

  return (
    <HeaderContainer {...props}>
      <Logo w="100px" color={["white", "white", "primary.500", "primary.500"]} />
      <Box display={{ base: "none", md: "block" }}>
        <MenuLinks />
      </Box>
      <MenuToggle toggle={handleToggle} isOpen={isOpen} />
      <Drawer header="Menu" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuLinks />
      </Drawer>
    </HeaderContainer>
  );
};

export default Header;
