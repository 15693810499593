import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { Box, Checkbox, Flex, Stack, Text, Collapse, Tag, TagLeftIcon, TagLabel, IconButton, Tooltip } from "@chakra-ui/react";
import { Category } from "../../../../types";
import useFilters, { FilterContext, FiltersContextVariables } from "../hooks/useFilters";
import DevIcon from "../../common/Icon";
import Link from "next/link";
import { createCategorySlug } from "../../../utils/create-slug";

interface CategoryWithSelected extends Category {
  selected?: boolean;
}

interface CategoryWithChildren {
  id: string;
  name: string;
  description?: Category["description"];
  children?: CategoryWithSelected[];
  parent?: Category["parent"];
  icon?: string;
  selected?: boolean;
}

interface CategoryParentItemProps {
  category: CategoryWithChildren;
  isLink?: boolean;
  onChange?: React.Dispatch<React.SetStateAction<string[]>>;
}

interface CategoryItemProps {
  isLink?: boolean;
  category: CategoryWithSelected;
  onChange: (category: CategoryWithSelected) => () => void;
  onChangeTag: (tag: any) => void;
}

const CategoryItem = ({ category, onChange, onChangeTag, isLink = false }: CategoryItemProps) => {
  const [isTagsOpened, setIsTagsOpened] = useState(false);
  const { filters } = useFilters();

  const toggleOpen = () => {
    setIsTagsOpened(prev => !prev);
  };

  return (
    <Box width="100%">
      <Flex alignItems="center">
        {isLink ? (
          <Link title={`Top ${category.name} interview questions`} href={`/interview-questions/${createCategorySlug(category.name)}`}>
            <Flex paddingLeft={2} _hover={{ textDecoration: "underline" }}>
              {category.icon && (
                <Flex mr={2} maxWidth="20px" justifyContent="center" alignItems="center">
                  <DevIcon name={category.icon} />
                </Flex>
              )}
              <Text fontSize="sm">{category.name}</Text>
            </Flex>
          </Link>
        ) : (
          <Checkbox colorScheme="purple" key={category.id} size="lg" isChecked={category.selected} onChange={onChange(category)}>
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <Flex>
                {category.icon && (
                  <Flex mr={2} maxWidth="20px" justifyContent="center" alignItems="center">
                    <DevIcon name={category.icon} />
                  </Flex>
                )}
                <Text fontSize="sm">{category.name}</Text>
              </Flex>
            </Flex>
          </Checkbox>
        )}

        {/* TODO remove when sort tags by answers */}
        {/*{(category.tags?.length || 0) > 0 && (*/}
        {/*  <Tooltip label={isTagsOpened ? "Expand topics" : "Collapse topics"}>*/}
        {/*    <IconButton ml={2} size="xs" aria-label="expand" onClick={toggleOpen} icon={!isTagsOpened ? <IoIosArrowDown /> : <IoIosArrowUp />}>*/}
        {/*      Topics*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
      </Flex>

      <Box mt={3}>
        <Collapse in={isTagsOpened} animateOpacity>
          {category.tags?.map(item => (
            <Tag
              cursor="pointer"
              background={filters.tags?.find(tag => tag.id === item?.id) && "primary"}
              mb={2}
              ml={1}
              mr={1}
              key={item?.id}
              onClick={() => onChangeTag(item)}
            >
              <TagLabel>{item?.name}</TagLabel>
            </Tag>
          ))}
        </Collapse>
      </Box>
    </Box>
  );
};

const CategoryParentItem = ({ category, isLink }: CategoryParentItemProps) => {
  const { filters, addFilter, removeFilter } = useFilters();

  const selectedChildren = useMemo(() => category.children?.filter(item => item.selected) || [], [category]);

  const handleChangeChildren = useCallback(
    (child: Category) => () => {
      const isChecked = selectedChildren.find(item => item.id === child.id);
      if (isChecked) {
        removeFilter({ type: "category", value: child });
      } else {
        addFilter({ type: "category", value: child, icon: category.icon });
      }
    },
    [addFilter, category.icon, removeFilter, selectedChildren],
  );

  const handleToggleTag = useCallback(
    (tag: any) => {
      if (filters.tags?.some(item => item.id === tag.id)) {
        removeFilter({ type: "tags", value: tag });
      } else {
        addFilter({ type: "tags", value: tag });
      }
    },
    [addFilter, filters.tags, removeFilter],
  );

  return (
    <Flex direction="column" mb={2}>
      <Text fontWeight="bold" mb={2}>
        {category.name}
      </Text>

      <Stack mt={1} spacing={1.5}>
        {category.children?.map((child, index: number) => (
          <CategoryItem isLink={isLink} key={child.id} category={child} onChange={handleChangeChildren} onChangeTag={handleToggleTag} />
        ))}
      </Stack>
    </Flex>
  );
};

interface CategoriesProps {
  categories?: Category[];
  isLink?: boolean;
}

export default function Categories({ categories, isLink }: CategoriesProps) {
  const { filters } = useContext<FiltersContextVariables>(FilterContext);

  const converted = useMemo(() => {
    return categories
      ?.filter(item => !item.parent)
      .map(item => ({
        ...item,
        icon: item.icon || "",
        selected: filters.categories?.some(category => category.id === item.id),
        children: categories
          ?.filter(child => child.parent === item.id)
          .map(child => ({ ...child, icon: child?.icon, selected: filters.categories?.some(category => category.id === child.id) })),
      }));
  }, [categories, filters]);

  return (
    <Flex direction="column">
      {converted?.map(category => (
        <CategoryParentItem isLink={isLink} key={category.id} category={category} />
      ))}
    </Flex>
  );
}

interface CategoriesContextVariables {
  selectedCategories: Category[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<Category[]>>;
  categories?: Category[];
}

export const CategoriesContext = createContext<CategoriesContextVariables>({} as any);
