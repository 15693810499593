import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import ReactMarkdown from "react-markdown";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";

interface Props {
  content: string;
  className?: string;
}

const MarkdownWrapper = ({ content, className, ...props }: Props) => (
  <ReactMarkdown
    components={ChakraUIRenderer({
      code({ node, inline, className, children: codeChildren, ...props }) {
        const match = /language-(\w+)/.exec(className || "");

        return !inline ? (
          // eslint-disable-next-line react/no-children-prop
          // @ts-ignore
          <SyntaxHighlighter
            language="javascript"
            customStyle={{
              borderRadius: "0.5rem",
              background: "#32324E",
              border: "1px solid #32324E",
              margin: "1rem 0",
            }}
            style={vscDarkPlus as any}
            PreTag="div"
            {...props}
          >
            {String(codeChildren).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code className={className} style={{ background: "rgba(250, 250, 250, 0.1)", color: "#8054FF", padding: "2px 4px", borderRadius: "5px" }} {...props}>
            {codeChildren}
          </code>
        );
      },
    })}
  >
    {content}
  </ReactMarkdown>
);

export default MarkdownWrapper;
