import { Button, Drawer, DrawerBody, DrawerFooter, DrawerContent, DrawerHeader, DrawerOverlay, DrawerCloseButton } from "@chakra-ui/react";
import React from "react";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onApply?: () => void;
  header?: string;
}

export default function DrawerComponent({ children, header, isOpen, onClose, onApply }: Props) {
  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent background="#32324E" borderRadius={5}>
        <DrawerHeader borderBottomWidth="1px" position="relative">
          <DrawerCloseButton top="50%" transform="translateY(-50%)" />
          {header}
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        {onApply && (
          <DrawerFooter borderTopWidth="1px">
            <Button background="primary" size="sm" onClick={onApply}>
              Apply
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}
