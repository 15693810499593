import { Box, BoxProps, Flex } from "@chakra-ui/react";

interface SidebarProps extends BoxProps {
  children: React.ReactNode;
}

const Container = ({ children, className, ...props }: SidebarProps) => {
  return (
    <Box className={className} as="div" maxW="1240px" margin="0 auto" w="100%" {...props}>
      {children}
    </Box>
  );
};

export default Container;
