import { Flex } from "@chakra-ui/react";
import Container from "../Layout/Container";
import Link from "next/link";

export default function Footer() {
  return (
    <Flex
      fontSize="10px"
      textDecoration="underline"
      paddingLeft="15px"
      paddingRight="15px"
      paddingTop={5}
      paddingBottom={5}
      as="footer"
      height="50px"
      bg="header"
      mt={50}
    >
      <Container pr={["0", "15px", "0", "15px"]}>
        <Flex justifyContent="center" alignItems="center">
          <Link href="/sitemap">sitemap</Link>
        </Flex>
      </Container>
    </Flex>
  );
}
