import { Flex, Tag } from "@chakra-ui/react";
import DevIcon from "../../common/Icon";
import Link from "next/link";
import { createCategorySlug } from "../../../utils/create-slug";

export default function AnswerCategoryLink({ icon, name, ...rest }: { icon: string; name: string; colorScheme?: string }) {
  return (
    <Link
      title={`Top ${name} interview questions`}
      onClick={(e: any) => e.stopPropagation()}
      color="#ffffff"
      href={`/interview-questions/${createCategorySlug(name)}`}
    >
      <Tag colorScheme="primary" {...rest}>
        <Flex mr={1}>{icon && <DevIcon name={icon as string} />}</Flex>
        <span>{name}</span>
      </Tag>
    </Link>
  );
}
