import MenuItem from "./MenuItem";
import { Box, Stack, useColorMode } from "@chakra-ui/react";

const MenuLinks = () => {
  return (
    <Box flexBasis={{ base: "100%", md: "auto" }}>
      <Stack spacing={8} align="flex-start" justify={["flex-start", "flex-start", "flex-end", "flex-end"]} direction={["column", "column", "column", "row"]}>
        <MenuItem to="/interview-questions">Questions</MenuItem>
      </Stack>
    </Box>
  );
};

export default MenuLinks;
