import Link from "next/link";
import { Text, TextProps } from "@chakra-ui/react";

interface MenuItemProps extends TextProps {
  children: React.ReactNode;
  isLast?: boolean;
  to?: string;
}

const MenuItem = ({ children, isLast, to = "/", ...rest }: MenuItemProps) => {
  return (
    <Link href={to}>
      <Text
        display="block"
        fontFamily={{
          base: "heading",
        }}
        {...rest}
      >
        {children}
      </Text>
    </Link>
  );
};

export default MenuItem;
