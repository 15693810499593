import { Flex, FlexProps } from "@chakra-ui/react";

interface NavBarContainerProps extends FlexProps {
  children: React.ReactNode;
}

const HeaderContainer = ({ children, ...props }: NavBarContainerProps) => {
  return (
    <Flex wrap="wrap" w="100%" bg="header" borderBottom="1px solid #6A41F5" fontWeight="bold" position="sticky" top={0} zIndex={2} {...props}>
      <Flex
        paddingLeft="15px"
        paddingRight="15px"
        paddingTop={5}
        paddingBottom={5}
        as="nav"
        maxW="1240px"
        margin="0 auto"
        justify="space-between"
        w="100%"
        color="white"
        align="center"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default HeaderContainer;
