import { Box, useColorMode } from "@chakra-ui/react";

interface SidebarProps {
  children: React.ReactNode;
}

const Sidebar = ({ children }: SidebarProps) => {
  const { colorMode } = useColorMode();
  return (
    <Box w="480px" display={["none", "none", "none", "block"]} borderRadius="20px" p="30px" bg="bar" position="sticky" top="100px">
      {children}
    </Box>
  );
};

export default Sidebar;
