import React from "react";
import { Box, IconButton } from "@chakra-ui/react";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

interface MenuToggleProps {
  toggle: () => void;
  isOpen: boolean;
}

const MenuToggle = ({ toggle, isOpen }: MenuToggleProps) => {
  return (
    <Box display={{ base: "block", md: "none" }}>
      <IconButton onClick={toggle} variant="ghost" size="large" aria-label="menu" icon={isOpen ? <IoClose /> : <HiOutlineMenuAlt3 />} />
    </Box>
  );
};

export default MenuToggle;
